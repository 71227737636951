import React from "react"
import LogoSVG from '../assets/svgs/logo.svg'

const Logo = (props) => {
  return (
    <div className={"logo " + props.className} style={{ height: props.height, padding: props.padding }}>
      <LogoSVG />
    </div>
   )
}
export default Logo

Logo.defaultProps = {
  height: '80px',
  padding: '20px'
}

