import React from "react"
import loadingGIF_image from '../assets/gifs/loading.gif'

const LoadingGIF = (props) => {
  return (
    <div style={{width: props.width}}>
      <img style={{width: '100%'}} src={loadingGIF_image} alt="Loading indicator" />
    </div>
  )
}
export default LoadingGIF;
LoadingGIF.defaultProps = {
  width: '50px'
}