//This is used for local development. It loads in the config.json as opposed to the env set variables. 

var config = {};
config.public = {
  bta_api_url : "https://api.bookthatapp.com/v1",
  shopify_api_url :`${process.env.GATSBY_SHOPIFY_API_DOMAIN}.myshopify.com/admin/api/2020-07`,
  oauth_domain: process.env.GATSBY_AWS_OAUTH_DOMAIN,
  api_domain: process.env.GATSBY_AWS_API_DOMAIN,
  api_stage: process.env.GATSBY_AWS_API_STAGE,
  redirectSignIn: process.env.GATSBY_AWS_OAUTH_LOGIN_CALLBACK,
  redirectSignOut: process.env.GATSBY_AWS_OAUTH_LOGOUT_CALLBACK,
  region: process.env.GATSBY_AWS_REGION,
  identityPoolId: process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
  userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.GATSBY_AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
  api_key: process.env.GATSBY_AWS_API_GATEWAY_API_KEY
}
// console.log(config)
module.exports.config = config;