import { APICall } from "./api-client"

export const APIGetVendors = () => {
  return new Promise((resolve, reject) => {
    APICall("GET", "vendors")
      .then(response => {
        let vendor_data = response.data.data

        let vendor_data_array  = []

        if(vendor_data.length > 0){
          vendor_data.forEach(vendor => {
            vendor_data_array.push(vendor.vendor)
          });
        }

        vendor_data_array.sort();
        resolve(vendor_data_array)
      })
      .catch(error => {
        reject(error)
      })
  })
}

