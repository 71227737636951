import React, { useContext } from "react"
import { signOut } from "../scripts/api-client";
import Icon, {  LogoutOutlined } from '@ant-design/icons';
import { Link } from "gatsby"
import { Layout, Menu, Divider, Button, Typography, Space } from "antd"

import Logo from '../components/logo'
import ProfileTile from '../components/profileTile'
import {
  GlobalStateContext,
  GlobalDispatchContext
} from "../context/GlobalContextProvider"

import user_icon_svg from '../assets/svgs/user_icon.svg'
import account_icon_svg from '../assets/svgs/account_icon.svg'
import bookings_icon_svg from '../assets/svgs/bookings_icon.svg'
import experiences_icon_svg from '../assets/svgs/experiences_icon.svg'
import blackouts_icon_svg from '../assets/svgs/blackouts_icon.svg'

const { Sider } = Layout;
const { Text } = Typography;

const UserIcon = props => <Icon className="wtc-icon users-icon " component={user_icon_svg} />;
const AccountIcon = props => <Icon className="wtc-icon account-icon " component={account_icon_svg} />;
const BookingsIcon = props => <Icon className="wtc-icon bookings-icon " component={bookings_icon_svg} />;
const ExperiencesIcon = props => <Icon className="wtc-icon experiences-icon " component={experiences_icon_svg} />;
const BlackoutsIcon = props => <Icon className="wtc-icon blackouts-icon " component={blackouts_icon_svg} />;

const Sidebar = ({ defKey, children }) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  
  const changeRoute = (event) => {
    console.log("ROUTE CHANGE", event.key)
    dispatch({ type:'ROUTE', route: event.key});
  }

  const handleSignOut = async () => {
    await signOut();
    dispatch({ type:'AUTH', auth_state: false, vendor: "", role: "", role_level: "", username: "", loading: true });
  }

  const renderAdminMenu = () => {
    return <Menu className="navigation" selectedKeys={[state.route]}>
      <Menu.Item key="/users" onClick={changeRoute} icon={<UserIcon />}>
        <Link to="/users">Users</Link>
      </Menu.Item>
      <Menu.Item key="/account" onClick={changeRoute} icon={<AccountIcon />}>
        <Link to="/account">Account</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled={state.profile ? false : true} key="/bookings" onClick={changeRoute} icon={<BookingsIcon />}>
        <Link to="/bookings">Bookings</Link>
      </Menu.Item>
      <Menu.Item disabled={state.profile ? false : true } key="/experiences" onClick={changeRoute} icon={<ExperiencesIcon />}>
        <Link to="/experiences">Experiences</Link>
      </Menu.Item>
      <Menu.Item disabled={state.profile ? false : true } key="/blackouts" onClick={changeRoute} icon={<BlackoutsIcon />}>
        <Link to="/blackouts">Blackouts</Link>
      </Menu.Item>
    </Menu>
  }

  const renderOperatorMenu = () => {
    return <Menu className="navigation" selectedKeys={[state.route]}>
      <Menu.Item key="/bookings" onClick={changeRoute} icon={<BookingsIcon />}>
        <Link to="/bookings">Bookings</Link>
      </Menu.Item>
      <Menu.Item key="/experiences" onClick={changeRoute} icon={<ExperiencesIcon />}>
        <Link to="/experiences">Experiences</Link>
      </Menu.Item>
      <Menu.Item key="/blackouts" onClick={changeRoute} icon={<BlackoutsIcon />}>
        <Link to="/blackouts">Blackouts</Link>
      </Menu.Item>
      <Menu.Item key="/account" onClick={changeRoute} icon={<AccountIcon />}>
        <Link to="/account">Account</Link>
      </Menu.Item>
    </Menu>
  }

  return (
      <Sider
        className="sidebar"
        width="260px"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}>
        <Logo className="appLogo" />
        <nav>
          <ProfileTile />
          {state.role === "operator" ? renderOperatorMenu() : renderAdminMenu()}
        </nav>
        <div className="menu-footer">
          <Button type="link" className="center" onClick={handleSignOut} style={{padding: 0}}><Space size={5}><LogoutOutlined /><b>Log Out</b></Space></Button>
          <Divider plain style={{marginTop: "5px"}}></Divider>
          <Text className="center copyright">©Welcome to Country 2020</Text>
        </div>
      </Sider>
   )
}
export default Sidebar

