
import { APICall } from "./api-client"
import moment from "moment-timezone"
moment.tz.setDefault("America/New_York");

export const APIDeleteBooking = (booking_id) => {
  return new Promise((resolve, reject) => {
    APICall("DELETE", `bookings?booking_id=${booking_id}`).then(response => {
      if (response.data.statusCode === 200) {
        resolve(response)
      }
      else {
        reject({ type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage })
      }
    }).catch(error => {
      reject({ type: "api", error })
    })
  })
}

export const APICreateBooking = (create_booking_request) => {
  return new Promise((resolve, reject) => {
    APICall("POST", `bookings`, create_booking_request)
      .then(response => {
        if (response.data.statusCode === 200) {
          resolve(response)
        }
        else {
          reject({ type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage })
        }
      }).catch(error => {
        reject({ type: "api", error })
      })
  })
}



export const APIGetBookings = (vendor, dateStart, dateFinish) => {
  return new Promise((resolve, reject) => {
    let start = dateStart.startOf('day').format("YYYY-MM-DDTHH:mm:ssZ")
    let finish = dateFinish.endOf('day').format("YYYY-MM-DDTHH:mm:ssZ")
    APICall("GET", `bookings?profile=${vendor}&start=${start}&finish=${finish}`, {},)
      .then(response => {
        var sortedBookings = response.data.data.slice().sort((a, b) => moment(a.starts_at).valueOf() - moment(b.starts_at).valueOf())
        resolve(sortedBookings)
      })
      .catch(error => {
        reject(error)
      })
  })
}


export const APISearchBookings = (vendor, type, query, dateStart, dateFinish) => {
  return new Promise((resolve, reject) => {
    let url = `search?type=${type}&query=${query}&profile=${vendor}`;
    if (dateStart)
      url += "&start=" + dateStart.startOf('day').format("YYYY-MM")
    if (dateFinish)
      url += "&finish=" + dateFinish.endOf('day').format("YYYY-MM")
    APICall("GET", url, {},)
      .then(response => {
        var sortedBookings = response.data.data.slice().sort((a, b) => moment(a.starts_at).valueOf() - moment(b.starts_at).valueOf())
        resolve(sortedBookings)
      })
      .catch(error => {
        reject(error)
      })
  })
}