import { APICall } from "./api-client"

export const APIGetUsers = () => {
  return new Promise ((resolve, reject) => {
    APICall("GET", "users")
      .then(response => {
        let user_data = response.data.data
        if(user_data.length > 0){
          user_data.forEach((user, index) => {
            user.key = index 
          });
        }
        resolve(user_data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const APIEditUser = (data, type) => {
  return new Promise ((resolve, reject) => {
    if(data.phone_number){
      data.phone_number = '+61' + data.phone_number;
    }
    
    let editUserRequest;
    if(type === "account"){
      editUserRequest = APICall("PUT", "accounts", data)
    }
    else{
      switch(data.role){
        case "operator": {
          editUserRequest = APICall("PUT", "users", data)
          break;
        }
        case "admin" : {
          editUserRequest = APICall("PUT", "admin", data)
          break;
        }
        case "superadmin" : {
          editUserRequest = APICall("PUT", "superadmin", data)
          break;
        }
        default : {
          reject({type: "validation", message: "ERROR: User role"})
          return;
        }
      }
    }
    editUserRequest.then(response => {
      if(response.data.statusCode === 200){
        resolve(response)
      }
      else{
        reject({type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage})
      }
    }).catch(error => {
      reject({type: "api", error})
    })
    
  })
  
}

export const APICreateUser = (data) => {
  return new Promise ((resolve, reject) => {
    if(data.phone_number){
      data.phone_number = '+61' + data.phone_number;
    }
    data.email_verified = 'true';
    // if(data.profile === ''){
    //   delete data.profile;
    // }

    Object.keys(data).forEach(data_key => {
      if(data[data_key] === '' || data[data_key] === undefined){
        delete data[data_key];
      }
    })

    let createUserRequest;
    switch(data.role){
      case "operator": {
        createUserRequest = APICall("POST", "users", data)
        break;
      }
      case "admin" : {
        createUserRequest = APICall("POST", "admin", data)
        break;
      }
      case "superadmin" : {
        createUserRequest = APICall("POST", "superadmin", data)
        break;
      }
      default : {
        reject({type: "validation", message: "ERROR: User role"})
        return;
      }
    }

    createUserRequest.then(response => {
      if(response.data.statusCode === 200){
        resolve(response)
      }
      else{
        reject({type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage})
      }
    }).catch(error => {
      reject({type: "api", error})
    })
  })
}


export const APIDeleteUser = (data) => {
  return new Promise ((resolve, reject) => {
    console.log(data)
    let deleteUserRequest;
    switch(data.role){
      case "operator": {
        deleteUserRequest = APICall("DELETE", "users", data)
        break;
      }
      case "admin" : {
        deleteUserRequest = APICall("DELETE", "admin", data)
        break;
      }
      case "superadmin" : {
        deleteUserRequest = APICall("DELETE", "superadmin", data)
        break;
      }
      default : {
        reject({type: "validation", message: "ERROR: User role"})
        return;
      }
    }

    deleteUserRequest.then(response => {
      if(response.data.statusCode === 200){
        resolve(response)
      }
      else{
        reject({type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage})
      }
    }).catch(error => {
      reject({type: "api", error})
    })
  })
}


export const APIResendCode = (username, email) => {
  return new Promise ((resolve, reject) => {
    let resendCodeRequest = APICall("POST", "resend_code", {username, email})

    resendCodeRequest.then(response => {
      if(response.data.statusCode === 200){
        resolve(response)
      }
      else{
        reject({type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage})
      }
    }).catch(error => {
      reject({type: "api", error})
    })
  })
}

