import { APICall } from "./api-client"

function sortExperiences(a, b) {
  // Use toUpperCase() to ignore character casing
  const titleA = a.title.toUpperCase();
  const titleB = b.title.toUpperCase();

  let comparison = 0;
  if (titleA > titleB) {
    comparison = 1;
  } else if (titleA < titleB) {
    comparison = -1;
  }
  return comparison;
}

export const APIGetExperiences = (vendor) => {
  return new Promise((resolve, reject) => {
    APICall("GET", `experiences?profile=${vendor}`, {}, )
      .then(response => {
        let experiences_data = response.data.data     
        
        experiences_data.sort(sortExperiences)
        
        resolve(experiences_data)
      })
      .catch(error => {
        reject(error)
      })
  })
}


export const APICreateTimeslot = (request_object) => {
  return new Promise((resolve, reject) => {
    APICall("PUT", `experiences?experience_id=${request_object.experience_id}&start=${request_object.start}&type=schedule`, {}, )
      .then(response => {
        let experiences_data = response.data.data        
        resolve(experiences_data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const APIEditPrices = (experience_id, request_object) => {
  return new Promise((resolve, reject) => {
    APICall("PUT", `experiences?experience_id=${experience_id}&type=price`, request_object )
      .then(response => {
        let experiences_data = response.data.data        
        resolve(experiences_data)
      })
      .catch(error => {
        reject(error)
      })
  })
}