import Amplify from "aws-amplify";
const { config } = require('../../config/config')

console.log(config.public)

export const oauth = {
  domain: config.public.oauth_domain,
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  redirectSignIn: config.public.redirectSignIn,
  redirectSignOut: config.public.redirectSignOut,
  responseType: "token"
};

export const awsConfig = {
  Analytics: {
    disabled: false
  },
  Auth: {
    oauth: oauth,
    region: config.public.region,
    identityPoolId: config.public.identityPoolId,
    userPoolId: config.public.userPoolId,
    userPoolWebClientId: config.public.userPoolWebClientId,
    mandatorySignIn: true,
  }
};

Amplify.configure(awsConfig);

// export const configureAmplify = () =>{
//   Amplify.configure(awsConfig);
// }

export const signInUrl = `https://${awsConfig.Auth.oauth.domain
  }/login?client_id=${awsConfig.Auth.userPoolWebClientId
  }&response_type=${awsConfig.Auth.oauth.responseType
  }&scope=${awsConfig.Auth.oauth.scope.join('+')
  }&redirect_uri=${awsConfig.Auth.oauth.redirectSignIn
  }`;
