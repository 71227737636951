import React, { useContext } from "react"
import { Avatar, Row, Col, Typography } from "antd"
import {
  GlobalStateContext
} from "../context/GlobalContextProvider"

const { Title, Text } = Typography

const ProfileTile = (props) => {
  const state = useContext(GlobalStateContext)
  let AvatarName = state.name ? state.name.charAt(0).toUpperCase() : state.username.charAt(0).toUpperCase()

  const renderOperatorTile = () => {
    return <Col flex="auto">
      <Title level={4}>{state.name ? state.name : state.profile}</Title>
      <Text className="font-small">Operator Portal</Text>
    </Col>
  }

  const renderAdminTile = () => {
    return <Col flex="auto">
      <Title level={4}>{state.name ? state.name : "Administrator"}</Title>
      <Text className="font-small">Administrator Portal</Text>
    </Col>
  }

  return (
    <Row className="profile-tile" justify="space-between" align="middle" style={{ flexWrap: "nowrap", margin: 0, padding: "20px" }}>
      <Col flex="48x" style={{ paddingRight: "10px" }}>
        <Avatar size={48}>
          {AvatarName}
        </Avatar>
      </Col>
      { state.role === "operator" ? renderOperatorTile() : renderAdminTile()}
    </Row>
  )
}

export default ProfileTile