import React, { useEffect, useReducer } from "react"
import { Hub } from "aws-amplify";
import { Spin } from 'antd';
import LoadingGIF from "../components/loadingGIF";
import { userInfo, userGroup, isAuth } from "../scripts/api-client";
import '../styles/app.less'
import Layout from "../layout/layout"
import { navigate } from "gatsby"
import moment from 'moment'
import { APIGetFrames, processFramesData } from "../scripts/frames"
import { APIGetVendors } from "../scripts/vendors"
import { APIGetAccount } from "../scripts/accounts"
import { APIGetBookings, APISearchBookings } from "../scripts/bookings"
import { APIGetBlackouts } from "../scripts/blackouts"
import { APIGetUsers } from "../scripts/users"
import { APIGetExperiences } from "../scripts/experiences"
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

Spin.setDefaultIndicator(<LoadingGIF width="20px" className="centered-loader" />)


const getRouteTitle = (route) => {
  let routeTitle = route.split('/');
  routeTitle = routeTitle[1];
  routeTitle = routeTitle.charAt(0).toUpperCase() + routeTitle.slice(1);
  return routeTitle;
}

const initialState = {
  auth_state: false,
  profile: '',
  role: '',
  username: '',
  imp_username: '',
  loading: true,
  usersLoaded: false,
  usersLoading: false,
  usersData: [],
  searchBookingsType: "",
  searchBookingsQuery: "",
  searchBookings: false,
  searchBookingsDateStart: null,
  searchBookingsDateFinish: null,
  searchBookingsLoaded: true,
  searchBookingsData: [],
  bookingsLoaded: false,
  bookingsData: [],
  bookingsDateStart: moment(),
  bookingsDateFinish: moment().add(1, 'M'),
  experiencesLoaded: false,
  experiencesLoading: false,
  experiencesData: [],
  blackoutsLoaded: false,
  blackoutsLoading: false,
  blackoutsData: [],
  accountLoaded: false,
  accountLoading: false,
  accountData: [],
  vendorsLoaded: false,
  vendorsLoading: false,
  vendorsData: [],
  newTimeslotStart: undefined,
  route: '/',
  routeTitle: '',
  framesData: "",
  framesLoaded: true,
  refreshVendors: false,
  refreshFrames: false,
  refreshAccount: false,
  refreshUsers: false,
  refreshSearchBookings: false,
  refreshBookings: false,
  refreshExperiences: false,
  refreshBlackouts: false,
  refreshDateDictionary: false,
  dateDictionary: {},
  dateDictionaryProcessed: false,
  daySelected: undefined,
  monthSelected: moment(),
  experienceSelected: undefined,
  blackoutSelected: undefined,
  timeslotSelected: undefined
}


function reducer(state, action) {
  console.log("STATE", state, action)
  switch (action.type) {
    case "REFRESH": {
      return {
        ...state,
        [action.toRefresh]: true
      }
    }
    case "SELECT": {
      return {
        ...state,
        [action.toSelect]: action.value
      }
    }
    case "NO_AUTH": {
      return {
        ...state,
        auth_state: false,
      }
    }
    case "AUTH": {
      return {
        ...state,
        auth_state: Boolean(action.auth_state),
        profile: action.profile,
        role: action.role,
        role_level: action.role_level,
        username: action.username,
        name: action.name,
        loading: action.loading
      }
    }
    case "ROUTE": {
      return {
        ...state,
        route: action.route,
        routeTitle: getRouteTitle(action.route)
      }
    }
    case "USERS": {
      return {
        ...state,
        usersLoaded: action.loaded,
        usersData: action.data,
        refreshUsers: false
      }
    }
    case "ACCOUNT": {
      return {
        ...state,
        accountLoaded: action.loaded,
        accountData: action.data,
        refreshAccount: false
      }
    }
    case "BOOKINGS": {
      return {
        ...state,
        bookingsLoaded: action.loaded,
        bookingsData: action.data,
        refreshBookings: false
      }
    }
    case "BOOKINGS_SEARCH_RESULTS": {
      return {
        ...state,
        searchBookingsLoaded: action.loaded,
        searchBookingsData: action.data,
        refreshSearchBookings: false,
        searchBookings: false
      }
    }
    case "BOOKINGS_SEARCH": {
      return {
        ...state,
        searchBookingsType: action.search_type,
        searchBookingsQuery: action.query,
        searchBookings: true
      }
    }
    case "BOOKINGS_SEARCH_DATE": {
      return {
        ...state,
        searchBookingsDateStart: action.start,
        searchBookingsDateFinish: action.finish,
        searchBookings: true
      }
    }
    case "BOOKINGS_DATE": {
      return {
        ...state,
        bookingsDateStart: action.start,
        bookingsDateFinish: action.finish
      }
    }
    case "EXPERIENCES": {
      return {
        ...state,
        experiencesLoaded: action.loaded,
        experiencesData: action.data,
        refreshExperiences: false
      }
    }
    case "BLACKOUTS": {
      return {
        ...state,
        blackoutsLoaded: action.loaded,
        blackoutsData: action.data,
        refreshBlackouts: false
      }
    }
    case "IMPERSONATE": {
      return {
        ...state,
        profile: action.profile,
        imp_username: action.imp_username,
        refreshVendors: false
      }
    }
    case "VENDORS": {
      return {
        ...state,
        vendorsLoaded: action.loaded,
        vendorsData: action.data,
        refreshVendors: false
      }
    }
    case "FRAMES": {
      return {
        ...state,
        framesLoaded: action.loaded,
        refreshFrames: false,
        framesData: action.data ? { ...state.framesData, ...action.data } : { ...state.framesData }
      }
    }
    case "DATE_DICTIONARY": {
      return {
        ...state,
        dateDictionary: action.data,
        dateDictionaryProcessed: action.processed,
        refreshDateDictionary: false
      }
    }
    case "NEW_TIMESLOT": {
      return {
        ...state,
        newTimeslotStart: action.start,
        // newTimeslotFinish: action.finish,
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Get bookings when filter is changed
  useEffect(() => {
    const getBookings = async () => {
      if (state.auth_state && state.profile) {
        dispatch({ type: 'BOOKINGS', loaded: false, data: [] });
        let bookings = await APIGetBookings(state.profile, state.bookingsDateStart, state.bookingsDateFinish);
        dispatch({ type: 'BOOKINGS', loaded: true, data: bookings });
      }
    }
    getBookings()
  }, [state.auth_state, state.bookingsDateStart, state.bookingsDateFinish, state.refreshBookings, state.profile]);

  // Search bookings
  useEffect(() => {
    const searchBookings = async () => {
      if ((state.auth_state && state.profile && state.searchBookings) || (state.refreshSearchBookings && state.auth_state)) {
        console.log("SEARCHING BOOKINGS")
        dispatch({ type: 'BOOKINGS_SEARCH_RESULTS', loaded: false, data: [] });
        await APISearchBookings(state.profile, state.searchBookingsType, state.searchBookingsQuery, state.searchBookingsDateStart, state.searchBookingsDateFinish)
          .then(response => {
            console.log(response)
            dispatch({ type: 'BOOKINGS_SEARCH_RESULTS', loaded: true, data: response });
          })
          .catch(error => {
            console.error(error)
            dispatch({ type: 'BOOKINGS_SEARCH_RESULTS', loaded: true, data: [] });
          })
      }
    }
    searchBookings()
  }, [state.auth_state, state.searchBookings, state.searchBookingsDateStart, state.searchBookingsDateFinish, state.refreshSearchBookings, state.profile, state.searchBookingsQuery, state.searchBookingsType]);

  // Get experiences
  useEffect(() => {
    const getExperiences = async () => {
      if (state.auth_state && state.profile) {
        dispatch({ type: 'EXPERIENCES', loaded: false, data: [] });
        let experiences = await APIGetExperiences(state.profile);
        dispatch({ type: 'EXPERIENCES', loaded: true, data: experiences });
      }
    }
    getExperiences()
  }, [state.auth_state, state.refreshExperiences, state.profile]);

  // Get blackouts
  useEffect(() => {
    const getBlackouts = async () => {
      if (state.auth_state && state.profile) {
        console.log("Getting blackouts")
        dispatch({ type: "BLACKOUTS", loaded: false, loading: true, data: [] });
        let blackouts = await APIGetBlackouts(state.profile);
        console.log("Get blackouts response:", blackouts)
        dispatch({ type: "BLACKOUTS", loaded: true, loading: false, data: blackouts });
      }
    }
    getBlackouts()
  }, [state.auth_state, state.refreshBlackouts, state.profile]);

  // Get frames
  useEffect(() => {
    const getFrames = async () => {
      dispatch({ type: 'FRAMES', loaded: false });
      setTimeout(async () => {
        let frames_data = {}
        frames_data[`${state.experienceSelected.external_id}_${state.monthSelected.format("YYYY-MM")}`] = await APIGetFrames(state.experienceSelected.external_id, state.monthSelected.format("YYYY-MM"));
        dispatch({ type: 'FRAMES', data: frames_data, loaded: true });
      }, 1000)
    }

    const processFrames = async () => {
      dispatch({ type: 'DATE_DICTIONARY', processed: false, data: [] });
      let date_dictionary = processFramesData(state.framesData[`${state.experienceSelected.external_id}_${state.monthSelected.format("YYYY-MM")}`], state.experienceSelected.capacity.capacity)
      dispatch({ type: 'DATE_DICTIONARY', processed: true, data: date_dictionary });
    }

    if (state.auth_state && state.experienceSelected && state.monthSelected) {
      if ((!state.framesData[`${state.experienceSelected.external_id}_${state.monthSelected.format("YYYY-MM")}`]) || (state.refreshFrames && state.auth_state)) {
        if (state.framesLoaded)
          getFrames();
      }
      else if ((state.framesLoaded && state.framesData[`${state.experienceSelected.external_id}_${state.monthSelected.format("YYYY-MM")}`]) || state.refreshDateDictionary) {
        processFrames();
      }
    }
  }, [state.auth_state, state.refreshFrames, state.refreshDateDictionary, state.experienceSelected, state.framesLoaded, state.monthSelected, state.framesData]);

  // Get account on load
  useEffect(() => {
    const getAccount = async () => {
      if (state.auth_state) {
        dispatch({ type: 'ACCOUNT', loaded: false, loading: true, data: [] });
        let user_data = await APIGetAccount();
        user_data.role = state.role;
        dispatch({ type: 'ACCOUNT', loaded: true, loading: false, data: user_data });
      }
    }
    getAccount()
  }, [state.role, state.auth_state, state.refreshAccount]);

  // Get Users on load if auth
  useEffect(() => {
    const getUsers = async () => {
      if (state.auth_state && state.role_level > 0) {
        dispatch({ type: 'USERS', loaded: false, loading: true, data: [] });
        let user_data = await APIGetUsers();
        dispatch({ type: 'USERS', loaded: true, loading: false, data: user_data });
      }
    }
    getUsers()
  }, [state.auth_state, state.role_level, state.refreshUsers]);

  // Get Vendors on load if auth
  useEffect(() => {
    const getVendors = async () => {
      if (state.auth_state && !state.vendorsLoaded && state.role_level > 0) {
        let vendor_data = await APIGetVendors();
        dispatch({ type: 'VENDORS', loaded: true, data: vendor_data });
      }
    }
    getVendors()
  }, [state.auth_state, state.refreshVendors, state.vendorsLoaded, state.role_level]);

  // Get initial auth state for app

  useEffect(() => {
    const getAuthState = async () => {
      Hub.listen("auth", ({ payload: { event, data } }) => {
        console.log(data, event)
        switch (event) {
          case 'signIn':
            console.log('user signed in');
            login();
            break;
          case 'signUp':
            console.log('user signed up');
            break;
          case 'signOut':
            console.log('user signed out');
            break;
          case 'signIn_failure':
            console.error('user sign in failed');
            break;
          case 'tokenRefresh':
            console.log('token refresh succeeded');
            break;
          case 'tokenRefresh_failure':
            console.error('token refresh failed');
            break;
          case 'configured':
            console.log('the Auth module is configured');
        }
      })

      const login = async () => {
        try {
          console.log("Trying to login...")
          let isAuthData = await isAuth();
          console.log(isAuthData)
          if (!isAuthData) {
            throw new Error("Not Auth'd");
          }
          console.log(isAuthData)
          let user_data = await userInfo();
          console.log(user_data);
          if (Object.keys(user_data).length > 0) {

            console.log(user_data)
            let user_group = await userGroup();
            let profile = user_data?.attributes?.profile;
            let role = user_group?.signInUserSession?.accessToken?.payload?.["cognito:groups"]?.[0];
            let role_level = 0;
            let name = user_data?.attributes?.given_name;
            let username = user_data.attributes.email;
            if (role === "admin")
              role_level = 1;
            else if (role === "superadmin")
              role_level = 2;
            dispatch({ type: 'AUTH', auth_state: true, profile, name, role, role_level, username, loading: false });
          }
          else {
            dispatch({ type: 'NO_AUTH', auth_state: false });
            await navigate("/login")
          }
        }
        catch (error) {
          console.log(error)
          dispatch({ type: 'NO_AUTH', auth_state: false });
          await navigate("/login")
        }
      }

      login();

      // let isAuthData = await isAuth();
      // console.log(isAuthData);
      // setTimeout(async () => {
      //   let isAuthData = await isAuth();
      //   console.log(isAuthData);

      //   l
      // }, 2000)

    }
    console.log("set window debug")
    window.LOG_LEVEL = 'DEBUG';
    console.log("Mount?")
    getAuthState()
  }, [])



  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <Layout>
          {children}
        </Layout>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider


// const handleFrameData = () => {
//   if(state.framesData[`${props.experience.external_id}_${monthSelected}`]){
//     if(state.framesData[`${props.experience.external_id}_${monthSelected}`].loaded){
//       console.log("FRAME DATA LOADED AND BEING PROCESSED")
//       let frames_dictionary_data = processFrameData(state.framesData[`${props.experience.external_id}_${monthSelected}`])
//       // let blackouts_dictionary_data = processBlackoutsData(state.blackoutsData[`${props.experience.external_id}_${monthSelected}`])
//       // console.log(blackouts_dictionary_data)
//       let date_dictionary = {...dateDictionary};
//       date_dictionary[`${props.experience.external_id}_${monthSelected}`] = {...frames_dictionary_data};
//       setdateDictionary(date_dictionary)
//     }
//   }
// }
// useEffect(handleFrameData, [state.framesData])
// useEffect(handleFrameData, [])


// const handleBlackoutData = () => {
//   if(state.blackoutsData[`${props.experience.external_id}_${monthSelected}`]){
//     if(state.blackoutsData[`${props.experience.external_id}_${monthSelected}`].loaded){
//       console.log("BLACKOUT DATA LOADED AND BEING PROCESSED")
//       let blackouts_dictionary_data = processBlackoutsData(state.blackoutsData[`${props.experience.external_id}_${monthSelected}`])
//       console.log(blackouts_dictionary_data)
//       let date_dictionary = {...blackoutDateDictionary};
//       date_dictionary[`${props.experience.external_id}_${monthSelected}`] = {...blackouts_dictionary_data};
//       setBlackoutDateDictionary(date_dictionary)
//     }
//   }
// }
// useEffect(handleBlackoutData, [state.blackoutsData])
// useEffect(handleBlackoutData, [])