
import { APICall } from "./api-client"
import moment from "moment-timezone"
moment.tz.setDefault("America/New_York");

export const APIDeleteBlackout = (blackout_id) => {
  return new Promise ((resolve, reject) => {
    APICall("DELETE", `blackouts?blackout_id=${blackout_id}`).then(response => {
      if(response.data.statusCode === 200){
        resolve(response)
      }
      else{
        reject({type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage})
      }
    }).catch(error => {
      reject({type: "api", error})
    })
  })
}

export const APICreateBlackout = (create_blackout_request) => {
  return new Promise ((resolve, reject) => {
    APICall("POST", `blackouts`, create_blackout_request)
      .then(response => {
        if(response.data.statusCode === 200){
          resolve(response)
        }
        else{
          reject({type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage})
        }
      }).catch(error => {
        reject({type: "api", error})
      })
  })
}

export const APIUpdateBlackout = (update_blackout_request) => {
  return new Promise ((resolve, reject) => {
    APICall("PUT", `blackouts`, update_blackout_request)
      .then(response => {
        if(response.data.statusCode === 200){
          resolve(response)
        }
        else{
          reject({type: "api", error: response.data.body ? response.data.body.message : response.data.errorMessage})
        }
      }).catch(error => {
        reject({type: "api", error})
      })
  })
}



export const APIGetBlackouts = (vendor) => {
  return new Promise((resolve, reject) => {
    // let start = moment().startOf('day').format("YYYY-MM-DDTHH:mm:ssZ")
    // let finish = dateFinish.endOf('day').format("YYYY-MM-DDTHH:mm:ssZ")
    APICall("GET", `blackouts?profile=${vendor}`, {}, )
      .then(response => {
        var sortedBlackouts = response.data.data.slice().sort((a,b) => moment(b.starts_at).valueOf() - moment(a.starts_at).valueOf())
        resolve(sortedBlackouts)
      })
      .catch(error => {
        reject(error)
      })
  })
}