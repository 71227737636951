import { APICall } from "./api-client"
import moment from "moment"

export const APIGetFrames = (experience_id, month) => {
  return new Promise ((resolve, reject) => {
    APICall("GET", `frames?month=${month}&experience=${experience_id}`)
      .then(response => {
        console.log(response.data)
        try {
          let frame_object = {
            loaded: true,
            frames: response.data.data.frames,
            blackouts: response.data.data.blackouts
          }
          console.log(frame_object)
          resolve(frame_object);
        }
        catch(error) {
          reject(error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const processFramesData = (frame_data, capacity) => {
  let date_dictionary = {}

  // Process frames
  for(let date of frame_data.frames){
    for(let timeslot of date.timeslots){
      let date_string_utc = moment(timeslot.start).utcOffset(moment().utcOffset()).format("YYYY-MM-DD");

      let remaining = capacity;
      for(let slot of timeslot.availability){
        remaining -= slot.bookings
      }

      let new_timeslot = {
        ...timeslot,
        capacity,
        remaining,
        start: moment(timeslot.start).utcOffset(moment().utcOffset()).format(),
        finish: moment(timeslot.finish).utcOffset(moment().utcOffset()).format()
      };
      
      if(!date_dictionary[date_string_utc])
        date_dictionary[date_string_utc] = { blackout: false, timeslots : [] }
        
      date_dictionary[date_string_utc].timeslots.push(new_timeslot)
    }
  }

  // Process blackouts
  for(let date of frame_data.blackouts){

    let index = 0;
    let start = moment(date.starts_at).utcOffset(moment().utcOffset())
    let finish = moment(date.finishes_at).utcOffset(moment().utcOffset())
    let difference = finish.diff(start, 'days');
    
    do {
      let date_string = moment(date.starts_at).utcOffset(moment().utcOffset()).add(index, 'days').format('YYYY-MM-DD');
      date_dictionary[date_string] = { blackout: true, timeslots: [] }
      index += 1;
    }
    while(index <= difference)
  }

  console.log("Date dictionary", date_dictionary)
  return date_dictionary;
}



// const processFrameData = (frame) => {
//   console.log(frame)
//   var date_dictionary = {};
//   if(frame.loaded && frame.days){
//     for(let day of frame.days){
//       console.log(day)
//       for(let timeslot of day.timeslots){
//         let new_timeslot = {
//           ...timeslot
//         };
//         new_timeslot.start = moment(timeslot.start).utcOffset(moment().utcOffset()).format()
//         new_timeslot.finish = moment(timeslot.finish).utcOffset(moment().utcOffset()).format()
//         let date_string_utc = moment(timeslot.start).utcOffset(moment().utcOffset()).format("YYYY-MM-DD");
//         console.log(date_string_utc)

//         if(!date_dictionary[date_string_utc])
//           date_dictionary[date_string_utc] = { timeslots : [] }
          
//         date_dictionary[date_string_utc].timeslots.push(new_timeslot)
//       }
//     }
//   }
//   return date_dictionary
// }

// const processBlackoutsData = (blackout) => {
//   console.log(blackout)
//   var date_dictionary = {};
//   if(blackout.loaded && blackout.days){
//     for(let day of blackout.days){
//       console.log(day)
//       console.log("START AND FINISH OF BLACKOUTS:", start, finish)
//       let start = moment(day.starts_at).utcOffset(moment().utcOffset())
//       let finish = moment(day.finishes_at).utcOffset(moment().utcOffset())

//       let difference = finish.diff(start, 'days');
//       console.log("DIFF:", difference)

//       let date_string = start.format('YYYY-MM-DD');
//       date_dictionary[date_string] = { all_day: true, start_string: start.format("DD-HH:mm:ss"), finish_string: finish.format("DD-HH:mm:ss"),start: start.endOf(), finish: start.endOf() }
//       for(let i = 1; i <= difference; i++){
//         date_string = moment(day.starts_at).add(i, 'days').format('YYYY-MM-DD');
//         date_dictionary[date_string] = { all_day: true, start_string: start.format("DD-HH:mm:ss"), finish_string: finish.format("DD-HH:mm:ss"), start: start.startOf(), finish: finish.endOf() }
//       }
//     }
//   }
//   return date_dictionary
// }