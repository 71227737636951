import aws4 from "aws4";
import AuthService from "./auth-service";
import axios from "axios";

const { config } = require('../../config/config')

const apiHost = config.public.api_domain;
const apiKey = config.public.api_key;
const apiStage = config.public.api_stage;

const authService = new AuthService();

export async function APICall(method, url, payload = {}) {
  console.log("API CALL")
  let request;
  let jwt = await authService.getJWT();
  if (method === "GET" || method === "get") {
    request = {
      host: apiHost,
      method: "GET",
      url: apiStage === "prd-alias" ? encodeURI(`https://${apiHost}/${url}`) : encodeURI(`https://${apiHost}/${apiStage}/${url}`),
      path: apiStage === "prd-alias" ? `/prd/${url}` : `/${apiStage}/${url}`,
      headers: {
        "x-api-key": apiKey,
        "token": jwt
      }
    }
  } else {
    request = {
      host: apiHost,
      method: method,
      data: payload,
      body: JSON.stringify(payload),
      url: apiStage === "prd-alias" ? encodeURI(`https://${apiHost}/${url}`) : encodeURI(`https://${apiHost}/${apiStage}/${url}`),
      path: apiStage === "prd-alias" ? `/prd/${url}` : `/${apiStage}/${url}`,
      headers: {
        'content-type': 'application/json',
        "x-api-key": apiKey,
        "token": jwt
      }
    }
  }

  const credentials = await authService.getCredentials();
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;
  let signedRequest = await aws4.sign(request, {
    accessKeyId,
    secretAccessKey,
    sessionToken
  })

  delete signedRequest.headers['Host']
  delete signedRequest.headers['Content-Length']
  console.log("SIGNED REQUEST", signedRequest)
  return axios(signedRequest);
}

export async function signIn() {
  // console.log("Signing user in...")
  let status = await authService.signIn();
  return status
}

export async function signOut() {
  // console.log("Signing user out...")
  let status = await authService.signOut();
  return status
}

export async function isAuth() {
  console.log("Checking auth status...")
  let status = await authService.isAuthenticated()
  console.log("STATUS:", status)
  return status;
}

export async function userGroup() {
  // console.log("Getting user group...")
  let user_group = await authService.getUserGroup();
  // console.log("GROUP:", user_group)
  return user_group;
}

export async function userInfo() {
  // console.log("Getting user info...")
  let user_info = await authService.getUserInfo()
  // console.log("USER INFO:", user_info)
  return user_info;
}
