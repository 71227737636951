import React, { useContext } from "react"
import LoadingGIF from "../components/loadingGIF"
import LoginBackground from '../assets/svgs/login_bg.svg'
import favicon from '../assets/images/favicon.ico'
import Logo from "../components/logo"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import { Layout } from "antd"
import Sidebar from './sidebar';
import {
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { Helmet } from "react-helmet"

const Container = ({ children }) => {
  const state = useContext(GlobalStateContext)
  
  const renderLoginLayout = () => {
    return <Layout className="login-portal" style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden"
      }}>
        <div className="background-container">
          <LoginBackground className="background"/>
        </div>
        <div className="foreground">
          <Layout>
            { children }
          </Layout>
        </div>
      </Layout>
    
  }

  const renderAppLayout = () => {
    return <Layout className="app-page" style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden"
      }}>
        <Sidebar/>
        <Layout>
          { children }
        </Layout>
      </Layout>
    
  }

  const renderLoading = () => {
    return <Layout className={`center loading-app ${state.loading ? "loading" : "loaded"}`} style={{
      height: "100vh",
      width: "100vw",
      overflow: "hidden"
    }}>
      <div className="background-container">
        <LoginBackground className="background"/>
      </div>
      <div className="foreground">
        <Logo height="120px" padding="20px 20px 40px 20px"/>
        <LoadingGIF />
      </div>
    </Layout>
  }
  return (
      <div id="app">
        <SEO title={state.routeTitle}/>
        <Helmet>
          <link rel="icon" href={favicon} />
          {/* Start of welcometocountry Zendesk Widget script */}
          <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=f417199f-8752-4940-954a-1e16d53b023d"> </script>
          {/* End of welcometocountry Zendesk Widget script */}
        </Helmet>
        {renderLoading() }
        { state.auth_state ? renderAppLayout() : renderLoginLayout() }
      </div>
   )
}
Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container

