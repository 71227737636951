import { APICall } from "./api-client"

export const APIGetAccount = () => {
  return new Promise((resolve, reject) => {
    APICall("GET", "accounts")
      .then(response => {
        let user_data = {};
        if(response.data.data.UserAttributes.length > 0){
          response.data.data.UserAttributes.forEach((attribute, index) => {
            user_data[attribute.Name] = attribute.Value
          });
          // user_data.role = state.role;
          
          user_data.username = response.data.data.Username;
          if(!user_data.given_name)
            user_data.given_name = "";
          if(!user_data.family_name)
            user_data.family_name = ""; 
        }
        resolve(user_data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
