import { Auth } from "aws-amplify";
import { awsConfig, signInUrl } from "./auth-config";



class AuthService {
  constructor(history) {
    this.history = history;
    console.log(awsConfig);
    // this.configureAmplify();
  }

  // configureAmplify = async () => {
  //   await 
  //   console.log(Amplify);
  // }

  isAuthenticated = async () => {
    // console.log("?")


    // let user = await Auth.currentAuthenticatedUser();
    // console.log(user)

    // user.then(response => {
    //   console.log(response)
    // }).catch(error => {
    //   console.error(error)
    // })
    // console.log("AUTH TEST 1:", await Auth.currentCredentials())
    // console.log("AUTH TEST 2:", await Auth.currentAuthenticatedUser())



    // console.log("AUTH TEST 3:", await Auth.currentSession())
    // console.log("AUTH TEST 4:", await Auth.currentUserCredentials())
    // console.log("AUTH TEST 5:", await Auth.userSession())
    // console.log("AUTH 1:", await Auth.currentUserInfo())
    try {
      // await Auth.userSession()
      let currentSession = await Auth.currentAuthenticatedUser();
      // let currentSession = await Auth.currentSession();
      return currentSession;

    } catch (error) {
      console.log(error)
      return false;
    }
  };


  getCredentials = async () => {

    try {
      const credentials = await Auth.currentCredentials();
      // console.log(credentials)
      return credentials;

    } catch (error) {
      console.log(error)
      return false;
    }
  };

  getJWT = async () => {
    try {
      let token = "";
      await Auth.currentSession().then(res => {
        let accessToken = res.getAccessToken()
        token = accessToken.getJwtToken()
      })
      return token;
    } catch (error) {
      console.log(error)
      return false;
    }
  };

  getUserInfo = async () => {
    try {
      const user_info = await Auth.currentUserInfo();
      console.log(user_info)
      return user_info;

    } catch (error) {
      console.log(error)
      return false;
    }
  };
  getUserGroup = async () => {
    try {
      const user_info = await Auth.currentAuthenticatedUser();
      // console.log(user_info)
      return user_info;

    } catch (error) {
      console.log(error)
      return false;
    }
  };

  signIn = () => {
    console.log("Signing in...")
    if (typeof window !== `undefined`) {
      window.location.href = signInUrl;
    }
  };

  signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}

export default AuthService;
